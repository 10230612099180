import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

class IndexPage extends React.Component {
  constructor() {
    super();
  }

  render() {
    return (

      <Layout>
        <SEO title="French Geometry" />
        {/* <section>
          <div className="scroll ep">
            <div>
Philip, What's the Password? - New ep out 12th of June! - Philip, What's the Password? - New ep out 12th of June! - Philip, What's the Password? - New ep out 12th of June! - 
            </div>
            <div>
            Philip, What's the Password? - New ep out 12th of June! - Philip, What's the Password? - New ep out 12th of June! - Philip, What's the Password? - New ep out 12th of June! - 
            </div>
          </div>
        </section> */}

<div className="releases">
  <div>
  <a href="https://frenchgeometry.bandcamp.com/album/philip-whats-the-password" target="_blank" >
    <img src="ep2.jpg" />
    <h2>Philip, What's the Password?</h2>
    <h3>Release date: June 12, 2020</h3>
    </a>
  </div>
  <div>
    <a href="https://fanlink.to/frgeo1" target="_blank" >
      <img src="ep1.jpg" />
      <h2>s/t</h2>
    </a>
    
  </div>
</div>
        
        


      </Layout>

    );
  }
}


export default IndexPage

